* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

form#login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

form#login h2 {
  color: #222;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

form#login input {
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.2em;
  border: 2px solid #eee;
  padding: .5em;
  margin: .5em;
  min-width: 300px;
  border-radius: 5px;
  transition: all .2s;
}

form#login input[type=submit] {
  background-color: #fff;
  color: #222;
}

form#login input:focus {
  border-color: orange;
}

.input-adornment {
  color: rgba(0, 0, 0, 0.54);
}

.font-size-std {
  font-size: 14px;
}
